.TrackStudentPage {
  p {
    margin-bottom: 15px;
    svg {
      vertical-align: middle;
      margin-top: -2px;
    }
  }
}

.DimensionContainer {
  display: flex;
  align-items: center;
}

.DimensionLabel {
  font-size: 20px;

  @media (min-width: 600px){
    text-align: right;
  }
}

.DimensionsColumn {
  font-size: 20px;
  margin-top: 30px;

  .DimensionsRow {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    @media screen and (max-width: 599px){
      display: block;
    }

    .DimensionsCell {
      width: 100%;
      margin-bottom: 5px;

      &:first-child {
        flex: 0 0 auto;
        width: 170px;
      }
    }
  }
}

.FrequencyModal {
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  background: rgba(#252525, 0.84);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);


  .CloseFrequencyModal {
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
    color: #ffffff;
  }

  .CompleteButtonContainer {
    position: absolute;
    bottom: 20px;
  }
}