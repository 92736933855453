.AppHeader {
  padding: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;

  .NavLink {
    margin-left: 20px;
  }

  .NavActiveLink {
    text-decoration: underline;
  }
}