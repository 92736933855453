.StudentInfo {
  img {
    display: block;
    width: 200px;
    border: 3px solid #ffffff;
    max-width: 100%;
    
    @media (max-width: 599px) {
      margin: 0 auto;
    }  
  }

  button {
    display: block;
    width: 200px;

    @media (max-width: 599px) {
      margin-left: auto;
      margin-right: auto;
    }  
  }
}

.CaseManagerBlock {
  width: 100%;
  font-size: 40px;
  border: 2px solid #ffffff;
  text-align: center;
  align-self: center;
  min-height: 100px;
  padding: 30px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 599px) {
    font-size: 20px;
  }  

  small {
    font-size: 70%;
    display: block;
  }
}

.ScheduleRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.AddButtonContainer {
  display: flex;
  align-items: center;
  width: 330px;
  margin: 10px;
  flex: 0 1 auto;
  max-width: calc(33.33% - 5px);
  
  @media (max-width: 1023px) {
    max-width: calc(50% - 5px);
  }  

  @media (max-width: 399px) {
    max-width: calc(100% - 5px);
  }  

  &:first-child {
    margin: 0 auto;
    border: none;
  }

  button {
    width: 100%;
    @media screen and (max-width: 1023px){
      padding-left: 5px;
      padding-right: 5px;
      font-size: 20px;
      line-height: 1.2;
    }
  }
}