.ActionButtonsRow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  button {
    white-space: nowrap;
    margin: 10px;
  }
}