.AppModalPageWrapper {
  background-color: rgba(0,0,0,0.5);
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  cursor: pointer;
}

.AppPageModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 360px;
  max-width: 90%;
  max-height: 90%;
  background-color: #ffffff;
  border-radius: 10px;
  color: #aeaeae;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .ProfileHeading {
    padding: 40px 40px 120px;
    background: #66cdcc;
    font-size: 30px;
    color: #ffffff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    text-align: center;
  }

  .AppPageModalContent {
    position: relative;
    overflow: auto;
    padding: 20px 40px 40px;
  }

  .AppModalLoading {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255,255,255,0.7);
    z-index: 1;
  }
  
  h1 {
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  p {
    font-size: 14px;
  }

  a {
    color: #f99f00;

    &:hover {
      color: #ae6f00;
      text-decoration: underline;
    }
  }
}