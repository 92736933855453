.FeaturesPage {
  flex: 1 1 auto;
  background-color: #66cdcc;
  display: flex;
  // box-shadow: 0 0 0 7px #326799 inset, 0 0 0 10px #ffffff inset;
  color: #ffffff;
  overflow: hidden;
  // padding: 10px;

  h1 {
    font-size: 60px;
    text-align: center;
    line-height: 1;
    margin-bottom: 30px;

    small {
      display: block;
      font-size: 24px;
      margin-top: 30px;
    }
  }
}
