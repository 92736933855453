.PrivacyPage {
  h1 {
    font-size: 60px;
    text-align: center;
    line-height: 1;
    margin-bottom: 30px;
  }

  h2 {
    font-size: 30px;
    margin-top: 25px; 
    margin-bottom: 15px; 
    color:#FFFFFF
  }

  .container p {
    margin-bottom: 15px; 

    a {
      text-decoration: underline;
      color: #FFFFFF;
    }
  }
}
