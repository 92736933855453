// TODO: make as common scss to avoid duplication

.AppModalPageWrapper {
  background-color: rgba(0,0,0,0.5);
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  cursor: pointer;
}

.AppPageModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 360px;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  background-color: #ffffff;
  padding: 40px;
  border-radius: 10px;
  color: #aeaeae;

  .AppPageModalContent {
    position: relative;
  }

  .AppModalLoading {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255,255,255,0.7);
    z-index: 1;
  }
  
  h1 {
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  // button {
  //   margin-right: 10px;
  // }

  p {
    font-size: 14px;
  }

  a {
    color: #f99f00;

    &:hover {
      color: #ae6f00;
      text-decoration: underline;
    }
  }
}