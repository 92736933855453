@keyframes onHighlightAnimation {
  0% {
      background-color: transparent;  
  }
  25% {
    background-color: #f99f00;  
  }
  50% {
    background-color: #f99f0050;  
  }
  75% {
    background-color: #f99f00;  
  }
  100% {
      background-color: transparent;
  }
}

.ScheduleCell {
  width: 100%;
  width: 330px;
  margin: 15px;
  border: 3px solid #fff;
  padding: 20px 20px 50px;
  margin: 0px;
  position: relative;
  
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-delay: .2s;

  button {
    position: absolute;
    bottom: 5px;
    right: 10px;
  }

  p {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 10px;
  }

  &.FlashHighlight {
    animation-name: onHighlightAnimation;
  }
}

.TimePicker {
  width: 100%;
  margin-bottom: 5px;

  :global(.rc-time-picker-input) {
    background-color: rgba(255,255,255,0.7);
    color: rgba(0, 0, 0, 0.87);
    border-color: white;
  }
}
