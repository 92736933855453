.PricingCard {
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  border: 2px solid white;
  height: 100%; 
}

.CheckCircle {
  color: #00000070;
}
