.DefaultPageLayout {
  flex: 1 1 auto;
  display: flex;
  color: #ffffff;
  overflow: hidden;
  padding: 10px;

  > div {
    box-shadow: 0 0 0 3px #ffffff;
  }
  
  h1 {
    font-size: 60px;
    text-align: center;
    line-height: 1;
    margin-top: 50px;
    margin-bottom: 50px;
    font-weight: 700;
    
    span {
      display: inline-block;
      font-size: 60%;
      vertical-align: top;
    }

    > div {
      display: inline-block;
      font-size: 15px;
      vertical-align: middle;
      margin-left: 15px;
    }
  }
}