.StudentInfo {
    img {
      display: block;
      width: 100%;
      
      
      @media (max-width: 599px) {
        margin: 0 auto;
      }  
    }
}