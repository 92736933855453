.Container {
  width: 330px;
  margin: 15px;
  border: 3px solid #fff;
  padding: 15px 20px;
  margin: 10px;
  position: relative;

  button {
    position: absolute;
    bottom: 5px;
    right: 10px;
  }

  @media (max-width: 639px) {
    width: calc(100% - 30px);
  }  

  p {
    text-align: center;
  }
}
