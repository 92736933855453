@import '../../assets/styles/modal.scss';

.AppPageModal {
  width: 500px;
}

.ExistingStudentData {
  color: black;
  font-size: 14px;
  margin-right: 15px;
}