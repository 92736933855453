@import './reset.css';
@import '~rc-time-picker/assets/index.css';

html {
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  font-weight: 400;
  height: 100%;
  color: #3f3f3f;
}

body {
  height: 100%;
}

#root {
  height: 100%;
  display: flex;
}

* {
  box-sizing: border-box;
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  overflow: auto;
  min-height: 100%;
  width: 100%;

  &.with-floating {
    padding-bottom: 85px;
  }
}

.container {
  padding: 0 16px;
  max-width: 1920px;
  margin: 0 auto;
  width: 100%;
  flex: 1 1 auto;
}

a {
  text-decoration: none;
  color: inherit;
}

.error-alert {
  color: #f44336;
}

.success-alert {
  color: #4caf50;
}
