.ClassroomPage {
  .StudentsListWrapper {
    overflow: auto;
  }
  
  .StudentsList {
    display: flex;
    flex-wrap: wrap;

    > a {
      width: 200px;
      margin: 3px;
      text-align: center;
      font-size: 20px;
      position: relative;
      text-align: center;
      max-width: calc(33.33% - 6px);

      :global(.MuiIconButton-root) {
        position: absolute;
        top: 5px;
        right: 5px;
      }
    }

    .AddButtonContainer {
      display: flex;
      align-items: center;
      width: 200px;
      margin: 3px;
      flex: 0 1 auto;
      max-width: calc(33.33% - 6px);
      
      &:first-child {
        margin: 0 auto;
        border: none;
      }

      button {
        width: 100%;
        @media screen and (max-width: 699px){
          padding-left: 5px;
          padding-right: 5px;
          font-size: 20px;
          line-height: 1.2;
        }
      }
    }
  }
}
