.Slot {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.AddButtonContainer {
  display: flex;
  justify-content: center;
  margin: 16px 0;
}