.ReportingResultPage {
  flex: 1 1 auto;
  background-color: #3ec3b3;
  display: flex;
  box-shadow: 0 0 0 7px #3ec3b3 inset, 0 0 0 10px #ffffff inset;
  color: #ffffff;
  overflow: hidden;
  padding: 10px;
  
  h1 {
    font-size: 60px;
    text-align: center;
    line-height: 1;
    margin-top: 50px;
    margin-bottom: 20px;
    font-weight: 700;
    
    span {
      display: inline-block;
      font-size: 60%;
      vertical-align: top;
    }
  }
}

.IncidentsTable {
  margin-top: 30px;
  line-height: 1.6;

  .IncidentsTableRow {
    display: flex;
    width: 100%;
    align-items: stretch;
    flex-wrap: wrap;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ffffff;
  }

  .IncidentsTableHead {
    font-weight: 700;

    @media (max-width: 1683px) {
      display: none;
    }
  }

  .IncidentsTableCell {
    padding: 15px 10px;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .IncidentsTableCellHeading {
      display: block;
      width: 100%;
      margin-bottom: 10px;
      font-weight: 700;
      white-space: nowrap;

      @media (min-width: 1684px) {
        display: none;
      }
    }

    .IncidentsTableCellReadonlyTextField {
      div {
        padding: 4px 8px;
        font-size: 16px;
        height: 59px;
      }
    }

    &:nth-of-type(1) {
      width: 40px;
    }

    &:nth-of-type(2),
    &:nth-of-type(3) {
      width: 100px;
    }

    &:nth-of-type(4) {
      width: 100px;
    }
    
    &:nth-of-type(5) {
      width: 210px;
    }
  }
}