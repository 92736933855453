.TimePicker {
  width: 100%;

  :global(.rc-time-picker-input) {
    background-color: rgba(255,255,255,0.7);
    color: rgba(0, 0, 0, 0.87);
    border-color: white;
    line-height: 1.5;
    padding: 8px 14px;
    font-size: 16px;
    min-height: 2.5em;
  }
}

.TimePickerPopup {
  :global(.rc-time-picker-panel-input-wrap) {
    padding: 8px 14px;
    font-size: 16px;
  }  
}

.TextField {
  :global(.MuiSelect-root.MuiInputBase-input) {
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 16px;
  }
}
