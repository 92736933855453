@import 'assets/styles/modal.scss';

.HomePage {
  flex: 1 1 auto;
  align-items: flex-end;
  display: flex;

  > div {
    height: 100%;
    flex: 0 1 auto;
  }

  img {
    display: block;
    max-width: 1200px;
    width: 100%;
    margin: 0 50px 0 auto;

    @media (min-width: 459px) and (max-width: 1279px) {
      max-width: 500px;
      margin: 0 auto;
    }  
  }

  h1 {
    font-size: 50px;
    text-align: center;
    line-height: 1;
    margin-top: 30px;
    margin-bottom: 30px;

    small {
      display: block;
      font-size: 24px;
      margin-top: 10px;
    }
  }
}

.AppPageModal {
  width: 80vw;
  text-align: center;
  @media (max-width: 767px) {
    width: 98vw;
  }
}