.Container {
  display: none;
}

@media print {
  .Container {
    display: block;
  }
}

:global(.printBody) {
  size: auto;
  font-size: 14px;
  margin: 20mm;
}

.Avatar {
  width: 100%;
  margin-bottom: 16px;
}

.OpLabel {
  font-weight: bold;
}

.PrintingNote {
  color: #7a7a7a;
  font-size: 10px;
  text-align: center;
  margin-top: 16px;
}
